import { PageProps } from 'gatsby';
import { ArticleData } from 'packages/innedit';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Item from '~/components/List/Item/Article';
import CMSView from '~/components/View';
import List from '~/containers/Espace/List';
import params from '~/params/article.json';
import TemplateEspace from '~/templates/espace';
import requireEspace, { EspaceProps } from '~/utils/requireEspace';
import { UserProps } from '~/utils/requireUser';

const ArticlesPage: FC<PageProps & EspaceProps & UserProps> = ({
  location,
  espace,
  user,
  params: { espaceId },
}) => {
  const { t } = useTranslation();
  const model = new ArticleData({ espaceId, params });

  return (
    <TemplateEspace espace={espace} user={user}>
      <CMSView>
        <List
          itemList={Item}
          itemPathnamePrefix={`/espaces/${espaceId}/articles/`}
          model={model}
          pathname={location.pathname}
          search={location.search}
          title={t('articles.title')}
          user={user}
        />
      </CMSView>
    </TemplateEspace>
  );
};

export default requireEspace(ArticlesPage);
